var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VRow',[_c('VCol',[_c('VDataTable',{attrs:{"items":_vm.connectedGroupsCompetency,"headers":_vm.connectedGroupsHeaders,"loading":_vm.connectedGroupsCompetencyLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VRow',{staticClass:"mb-4"},[_c('VCol',[_c('h3',[_vm._v(" Группы компетенций ")])]),_c('VCol',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('VBtn',{attrs:{"color":"primary","disabled":!_vm.selectedStaffPosition,"to":{ name : _vm.Names.R_ORGSTRUCTURE_POSITION_TO_COMPETENCY_GROUP,
                       query :
                         {
                           staffPositionId : _vm.selectedStaffPosition
                         },
                       params :
                         {
                           staffPositionId : _vm.selectedStaffPosition
                         }
                }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Добавить группу ")],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
              name : _vm.Names.R_COMPETENCY_MATRIX_POSITION_COMPETENCIES,
              params : {
                positionId : item.id
              }
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.companyName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyName)+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.unlinkGroupCompetency(item, false)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}])})],1)],1),_c('ConfirmDelete',{attrs:{"title":"Удалить","text":_vm.confirmDialogText},on:{"cancel":_vm.handleCancelDeleteLink,"submit":_vm.handleSubmitDelete},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }