<template>
  <div>
    <VRow>
      <VCol>
        <VDataTable
          :items="connectedGroupsCompetency"
          :headers="connectedGroupsHeaders"
          :loading="connectedGroupsCompetencyLoading"
        >
          <template #top>
            <VRow class="mb-4">
              <VCol>
                <h3>
                  Группы компетенций
                </h3>
              </VCol>
              <VCol
                cols="auto"
                class="d-flex align-center"
              >
                <VBtn
                  color="primary"
                  :disabled="!selectedStaffPosition"
                  :to="{ name : Names.R_ORGSTRUCTURE_POSITION_TO_COMPETENCY_GROUP,
                         query :
                           {
                             staffPositionId : selectedStaffPosition
                           },
                         params :
                           {
                             staffPositionId : selectedStaffPosition
                           }
                  }"
                >
                  <VIcon left>
                    fal fa-plus
                  </VIcon>
                  Добавить группу
                </VBtn>
              </VCol>
            </VRow>
          </template>
          <template #item.name="{ item }">
            <RouterLink
              :to="{
                name : Names.R_COMPETENCY_MATRIX_POSITION_COMPETENCIES,
                params : {
                  positionId : item.id
                }
              }"
            >
              {{ item.name }}
            </RouterLink>
          </template>
          <template #item.companyName="{item}">
            {{ item.companyName }}
          </template>
          <template #item.actions="{item}">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="unlinkGroupCompetency(item, false)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>
          </template>
        </VDataTable>
      </VCol>
    </VRow>

    <ConfirmDelete
      v-model="confirmDialog"
      title="Удалить"
      :text="confirmDialogText"
      @cancel="handleCancelDeleteLink"
      @submit="handleSubmitDelete"
    />
  </div>
</template>

<script>
import ConfirmDelete from '@front.backoffice/shared/src/components/ConfirmDelete.vue';

export default {
  name: 'UserCompanyCompetencyGroups',
  components: { ConfirmDelete },
  inject: ['Names'],
  props: {
    staffPosition: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      connectedGroupsCompetencyLoading: false,
      selectedStaffPosition: null,
      selectedFullStaffPosition: {},
      usersStaffPositions: [],
      connectedGroupsCompetency: [],
      connectedGroupsHeaders: [
        {
          text: 'UUID',
          value: 'id',
          width: '25%',
        },
        {
          text: 'Название',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Компания',
          value: 'companyName',
          width: '30%',

        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      confirmDialogText: '',
      confirmDialog: false,
      deleteLink: null,
      groupCompetencyLinkToDelete: null,
    };
  },
  computed: {
    /**
     *
     * @returns {string}
     */
    personId() {
      return this.$route.params.personId;
    },

    /**
     *
     * @returns {string}
     */
    companyId() {
      return this.$route.params.companyId;
    },

    queryStaffPosition() {
      return this.$route.query.staffPosition;
    },
  },

  watch: {
    confirmDialog(val) {
      if (!val) {
        this.deleteLink = null;
        this.groupCompetencyLinkToDelete = null;
        this.confirmDialogText = '';
      }
    },
    usersStaffPositions(staffPositions) {
      const staffPosition = staffPositions.find((sp) => sp.main);
      if (staffPosition && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPosition.id;
      }
      if (staffPositions.length && !this.selectedStaffPosition) {
        this.selectedStaffPosition = staffPositions[0].id;
      }

      this.selectedFullStaffPosition = staffPositions.find((el) => el.id === this.selectedStaffPosition);
    },
    queryStaffPosition(queryStaffPosition) {
      this.selectedStaffPosition = queryStaffPosition;
    },
    selectedStaffPosition() {
      this.getConnectedGroupCompetency();
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.selectedStaffPosition = this.queryStaffPosition;
        const { staffPositions } = await this.$di.api.Orgstructure.getStaffPositionsByUser({ userId: this.personId });
        this.usersStaffPositions = this.staffPositionsCompanyFilter(staffPositions);

        await this.getConnectedGroupCompetency();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async refreshData() {
      try {
        await this.fetch();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    handleSubmitDelete() {
      if (this.deleteLink) {
        this.handleSubmitDeleteLink();
      }
      if (this.groupCompetencyLinkToDelete) {
        this.deleteGroupCompetencyLink();
      }
    },

    async handleSubmitDeleteLink() {
      try {
        await this.$di.api.Orgstructure.unlinkStaffPositions(this.deleteLink);
        await this.refreshData();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.handleCancelDeleteLink();
      }
    },

    async deleteGroupCompetencyLink() {
      this.connectedGroupsCompetencyLoading = true;
      const selectedStaffPosition = this.usersStaffPositions.find((el) => el.id === this.selectedStaffPosition);
      const deleteCompetencyId = selectedStaffPosition.competencyGroupIds.findIndex(
        (el) => el === this.groupCompetencyLinkToDelete,
      );
      const newCompetencyGroupIds = [...selectedStaffPosition.competencyGroupIds];
      newCompetencyGroupIds.splice(deleteCompetencyId, 1);

      try {
        await this.$di.api.Orgstructure.positionUpdateV2({
          ...selectedStaffPosition,
          competencyGroupIds: newCompetencyGroupIds,
        });
        this.$di.notify.snackSuccess('Связь удалена');
        await this.refreshData();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.connectedGroupsCompetencyLoading = false;
        this.handleCancelDeleteLink();
      }
    },

    staffPositionsCompanyFilter(positions) {
      return positions.filter((el) => el.companyId === this.companyId);
    },

    handleCancelDeleteLink() {
      this.confirmDialog = false;
    },

    unlinkGroupCompetency(groupCompetency) {
      this.groupCompetencyLinkToDelete = groupCompetency.id;
      // eslint-disable-next-line max-len,vue/max-len
      this.confirmDialogText = `Удалить ${groupCompetency.name} из списка Групп компетенций?`;
      this.confirmDialog = true;
    },

    async getConnectedGroupCompetency() {
      if (!this.selectedStaffPosition) {
        this.connectedGroupsCompetency = [];
        return;
      }

      this.connectedGroupsCompetencyLoading = true;

      const selectedStaffPosition = this.usersStaffPositions.find(
        (el) => el.id === this.selectedStaffPosition,
      );
      const connectedGroupsResponse = await this.$di.api.CompetencyMatrix.GetCompetencyGroupsByIds({
        ids: selectedStaffPosition?.competencyGroupIds,
      });

      let connectedGroups = connectedGroupsResponse.competencyGroups;

      if (connectedGroupsResponse.competencyGroups.length) {
        const connectedGroupCompanyIds = connectedGroupsResponse.competencyGroups.map((el) => el.companyId);
        const connectedGroupsCompanyResponse = await this.$di.api.Account.GetCompaniesBatch({
          companyIds: connectedGroupCompanyIds,
        });

        connectedGroups = connectedGroupsResponse.competencyGroups.map((el) => {
          const matchCompany = connectedGroupsCompanyResponse.companies.find(
            (company) => company.id === el.companyId,
          );

          return {
            ...el,
            companyName: matchCompany.name,
          };
        });
      }

      this.connectedGroupsCompetencyLoading = false;

      this.connectedGroupsCompetency = connectedGroups;
    },
  },
};
</script>
